import { ConstraintsResourceEnum, ConstraintsSettingsNameEnum } from '../config/ConstraintsEnums';
import { FeatureIdEnum } from '../config/FeatureEnums';
export const featureConstraintsMap = [
  {
    featureid: `${FeatureIdEnum.Ews_Admin_Password}`,
    constraints: [
      {
        resource: `${ConstraintsResourceEnum.DeviceAdminConfig}`,
        settings: [
          `${ConstraintsSettingsNameEnum.Configured_ByUser}`,
          `${ConstraintsSettingsNameEnum.Credentials_ProposedPassword}`,
        ],
      },
      {
        resource: `${ConstraintsResourceEnum.AccountPolicy}`,
        settings: [
          `${ConstraintsSettingsNameEnum.Ews_Admin_MinimumPasswordLength}`,
          `${ConstraintsSettingsNameEnum.Ews_Admin_PasswordComplexity}`,
          `${ConstraintsSettingsNameEnum.Ews_Admin_PasswordAccountLockout}`,
          `${ConstraintsSettingsNameEnum.Ews_Admin_PasswordMaximumAttempts}`,
          `${ConstraintsSettingsNameEnum.Ews_Admin_PasswordResetAfterSecs}`,
          `${ConstraintsSettingsNameEnum.Ews_Admin_PasswordLockDurationSecs}`,
        ],
      },
    ],
  },
  {
    featureid: `${FeatureIdEnum.Service_Access_Code}`,
    constraints: [
      {
        resource: `${ConstraintsResourceEnum.ServiceUserConfig}`,
        settings: [`${ConstraintsSettingsNameEnum.SvcAccessCode}`],
      },
    ],
  },
  {
    featureid: `${FeatureIdEnum.PJL_Password}`,
    constraints: [
      {
        resource: `${ConstraintsResourceEnum.PrintConfiguration}`,
        settings: [`${ConstraintsSettingsNameEnum.PJL_Password}`],
      },
    ],
  },
  {
    featureid: `${FeatureIdEnum.ProxyServer}`,
    constraints: [
      {
        resource: `${ConstraintsResourceEnum.ProxyServer}`,
        settings: [`${ConstraintsSettingsNameEnum.ProxyServerAddress}`,
        `${ConstraintsSettingsNameEnum.ProxyServerPort}`,
        `${ConstraintsSettingsNameEnum.ProxyServerUser}`,
        `${ConstraintsSettingsNameEnum.ProxyServerPassword}`],
      },
    ],
  },
  {
    featureid: `${FeatureIdEnum.SNMP_V1_V2}`,
    constraints: [
      {
        resource: `${ConstraintsResourceEnum.SNMPConfig}`,
        settings: [
          `${ConstraintsSettingsNameEnum.SNMPV1V2_Enabled}`,
          `${ConstraintsSettingsNameEnum.SNMPV1V2_Access_Option}`,
          `${ConstraintsSettingsNameEnum.SNMPV1V2_ReadOnly_CommunityName}`,
          `${ConstraintsSettingsNameEnum.SNMPV1V2_WriteOnly_CommunityName}`,
          `${ConstraintsSettingsNameEnum.SNMPV1V2_ReadOnly_Public_Allowed}`,
        ],
      },
    ],
  },
  {
    featureid: `${FeatureIdEnum.SNMP_V3}`,
    constraints: [
      {
        resource: `${ConstraintsResourceEnum.SNMPConfig}`,
        settings: [
          `${ConstraintsSettingsNameEnum.SNMPV3_Enabled}`,
          `${ConstraintsSettingsNameEnum.SNMPV3_UserName}`,
          `${ConstraintsSettingsNameEnum.SNMPV3_Authentication_Passphrase}`,
          `${ConstraintsSettingsNameEnum.SNMPV3_Privacy_Passphrase}`,
          `${ConstraintsSettingsNameEnum.SNMPV3_Privacy_Algorithms}`,
          `${ConstraintsSettingsNameEnum.SNMPV3_MinimumPassword_Length}`,
          `${ConstraintsSettingsNameEnum.SNMPV3_Password_Complexity_Enabled}`,
          `${ConstraintsSettingsNameEnum.SNMPV3_Account_Lockout}`,
          `${ConstraintsSettingsNameEnum.SNMPV3_Maximum_Attempts}`,
          `${ConstraintsSettingsNameEnum.SNMPV3_Account_Reset_Lockout_Intervel}`,
          `${ConstraintsSettingsNameEnum.SNMPV3_Account_Reset_Lockout_Intervel}`,
        ],
      },
    ],
  },
  {
    featureid: `${FeatureIdEnum.Bt_Low_Energy}`,
    constraints: [
      {
        resource: `${ConstraintsResourceEnum.BluetoothConfig}`,
        settings: [`${ConstraintsSettingsNameEnum.Bt_Low_Energy}`],
      },
    ],
  },
  {
    featureid: `${FeatureIdEnum.CA_Certificate}`,
    constraints: [
      {
        resource: `${ConstraintsResourceEnum.CA_Certificate}`,
        settings: [`${ConstraintsSettingsNameEnum.CA_Certificate_File}`],
      },
    ],
  },
  {
    featureid: `${FeatureIdEnum.ID_Certificate}`,
    constraints: [
      {
        resource: `${ConstraintsResourceEnum.ID_Certificate}`,
        settings: [
          `${ConstraintsSettingsNameEnum.ID_Certificate_File}`,
          `${ConstraintsSettingsNameEnum.ID_Certificate_Password}`,
        ],
      },
    ],
  },
  {
    featureid: `${FeatureIdEnum.LDAP_SignIn_Setup}`,
    constraints: [
      {
        resource: `${ConstraintsResourceEnum.LDAP_Configuration}`,
        settings: [
          `${ConstraintsSettingsNameEnum.LDAP_SignIn}`,
          `${ConstraintsSettingsNameEnum.LDAP_Server_Address}`,
          `${ConstraintsSettingsNameEnum.LDAP_Port_Number}`,
          `${ConstraintsSettingsNameEnum.LDAP_Use_SSL}`,
          `${ConstraintsSettingsNameEnum.LDAP_Server_Authentication}`,
          `${ConstraintsSettingsNameEnum.LDAP_Bind_Prefix}`,
          `${ConstraintsSettingsNameEnum.LDAP_Admin_DN}`,
          `${ConstraintsSettingsNameEnum.LDAP_Admin_Password}`,
          `${ConstraintsSettingsNameEnum.LDAP_Bind_And_Search_Root}`,
          `${ConstraintsSettingsNameEnum.LDAP_match_name_with_attribute}`,
          `${ConstraintsSettingsNameEnum.LDAP_retrieve_user_email}`,
          `${ConstraintsSettingsNameEnum.LDAP_retrieve_user_name}`,
          `${ConstraintsSettingsNameEnum.LDAP_retrieve_user_group}`,
          `${ConstraintsSettingsNameEnum.LDAP_exact_match_on_group_attribute}`,
        ],
      },
    ],
  },
];
