import { Modal } from '@veneer/core';
import { colorGray4, colorGray7, colorGray10, fontSize3, lineHeight3 } from '@veneer/tokens/dist/tokens/tokens';
import styled from 'styled-components';
import { theme } from '../../GlobalStyles/GlobalStyles';
import { Button } from '@veneer/core';
import { RadioButton, RadioButtons } from '@veneer/core';
import * as Veneer from '@veneer/tokens/dist/tokens/tokens'

export const StyledRadioButtons = styled(RadioButtons)`
  > label .vn-radio-button__span-text {  
    font-family: 'Forma DJR UI' !important;
  }
  > label .vn-radio-button__icon::before {
    width: ${(props) => props.isWex && '10px'};
    height: ${(props) => props.isWex && '10px'};
  }
`;

export const Grid2 = styled.div`
  display: grid;
  grid-gap: 1px;
`;

export const BadgeLabel = styled.div`
  border-radius: 10px;
  padding: 1px 8px;
  box-sizing: border-box;
  white-space: nowrap;
`;

export const TextBlackH = styled.div``;

export const FlexRow = styled.div`
  display: flex;
`;
export const StyledButton = styled(Button)`
  &:hover {
    background: ${(props) =>
      props.isWex ? 'rgba(77, 82, 229, 0.1)' : 'rgba(2, 122, 174, 0.1)'};
  }
  ${(props) =>
    props.isWex &&
    `width: 92px;
   height: 36px ;
   padding: 8px 20px 8px 20px;
   gap: 8px;
   border-radius: 360px !important;
   border: 1px;
   opacity: 0px;`}
`;

export const FlexRowWithSpace = styled(FlexRow)`
  justify-content: space-between;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
`;

export const FlexColumnGrow = styled(FlexColumn)`
  flex-grow: 1;
`;

export const FlexColumnTree = styled(FlexColumn)`
  width: 280px;
`;

export const FlexColumnRight = styled(FlexColumn)`
  width: calc(100% - 280px);
`;

export const SideMenu = styled.div`
  position: sticky;
  top: 10px;
  height: 100%;
`;

export const TextBlackVariant1 = styled.div`
  font-family: ${theme.fontFamilyPrimary};
  font-size: ${fontSize3};
  line-height: ${lineHeight3};
  letter-spacing: 0.02em;
  color: ${(props) => (props.disable ? colorGray4 : colorGray7)};
  overflow-wrap: anywhere;
  padding-bottom: ${(props) => props.padding};
`;

export const TextBlackVariant2 = styled.div`
  font-family: ${theme.fontFamilyPrimary};
  font-size: ${fontSize3};
  line-height: ${lineHeight3};
  letter-spacing: 0.02em;
  color: ${(props) => (props.disable ? colorGray4 : colorGray10)};
  overflow-wrap: anywhere;
  padding-bottom: ${(props) => props.padding};
`;

export const InputWrap = styled.div`
${(props) =>
  props.isWex &&
  `width: 92px;
   height: 36px ;
   padding: 8px 20px 8px 20px;
   gap: 8px;
   border-radius: 360px !important;
   border: 1px;
   opacity: 0px;`}
  width: 400px;
  margin-bottom: 16px;
  &:last-child {
    width: 400px;
    margin-bottom: 0px;
    @media (max-width: 991px) {
      width: 100%;
    }
  }
  @media (max-width: 991px) {
    width: 100%;
  }
  }
`;

export const CustomModal = styled(Modal)`
  ${(props) =>
    props.isWex ? `.vn-modal--footer > div > button {
  width: 131px;
  height: 36px;
  border-radius: 360px !important;
  }`: `.vn-modal--footer > div > button {
    margin: 0;
    margin-right: 12px;
   }
  `}
 .vn-modal--dialog > div {
  background: ${Veneer.colorWhite};
}
  .vn-modal--content {
    @media only screen and (min-width: 992px) {
      min-width: 612px !important;
    }
  }
  @media only screen and (max-width: 991px) {
    max-width: 612px !important;
  }
  .vn-modal--footer {
    justify-content: right !important;
  }
`;

export const CheckBoxWrap = styled.div`
  margin-left: 32px;
  margin-bottom: 16px;
`;

export const ButtonSelectedLabel = styled.div`
  color: #404040;
  font-family: 'Forma DJR UI';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const cssFullHeight = { height: '100%' };
export const cssModalTableHeight = { height: '488px' }; // + header(60) + text(24) + footer(68) + 2 x padding(32) = 720px modal height
export const cssModalTableHeight8 = { height: '394px' }; // border(1) + header(40) + 8 * row(44) + border(1) = 394px
