
const useSystemLaunchers = () => {
  const launchers  = {
    'fb76eb54-dd81-4951-b084-ad336eef9805': 'accessibility',
    'd1f34b7f-4bb9-45c4-86a7-cbb7d8082bd3': 'app-gallery',
    '2b328d57-7fff-401c-9665-315ada3010f0': 'contacts',
    'cedab422-33b3-4638-b6a1-604e54525215': 'copy',
    'bf2e73e6-6f29-11e6-8133-a0481c7e824c': 'copy',
    '2e36615d-0172-4e0b-9abb-50c6445409ce': 'document',
    'bc858ffc-6f2a-11e6-9010-a0481c7e824c': 'fax',
    'a3ab4441-686a-4fe4-a0be-85749ac8393c': 'fax-polling',
    '1f91f218-ca35-4554-a2f3-16b0b28fea31': 'help',
    '552b2048-9c7a-4571-9424-4171e740b6c7': 'hpcc',
    '4ed2e666-85d8-47b6-ab77-7b2e390c0787': 'hpja',
    'd0c91c9f-4a6d-4ea7-9ecc-18030e478cd0': 'id-card',
    'c74293eb-04c1-4dff-b469-1c0e99fdbe8b': 'id-copy',
    '697749ba-b6ea-11eb-80d0-9ffe5c5f1620': 'info',
    '264c82cb-15fa-4833-a7c4-4f526182bb76': 'initial-setup',
    '8be4736f-26ab-459f-a415-b85dc8b157d3': 'jobs',
    '56ce9217-377d-4d5c-a950-a2ad37c07882': 'job-logs',
    '9da37e46-9b8a-4dc2-a24c-017fee6b088f': 'maintenance',
    'a78eeca1-8af3-4b4b-9764-208351f43eaf': 'maintenance',
    'e1ab8582-666d-442f-b620-0b5f58a7abee': 'personal-shortcuts',
    '6d777dcb-f62b-4cec-b536-c39078e14fc5': 'phone-book',
    '3c515974-6f2a-11e6-b714-a0481c7e824c': 'print',
    '02fecd9a-7fe7-4797-ad15-8127df2cfaad': 'print',
    '7b7efcc4-34ad-11ed-a261-0242ac120002': 'print-from-nw-folder',
    'dec7d1f3-81ea-460b-8a00-d2729786ec2f': 'print-from-storage',
    'c93bc831-99a8-454c-b508-236fc3a2a08f': 'print-from-usb',
    '12642a24-5e74-41a2-a154-20d20436abee': 'print-from-usb',
    '87550e5e-f927-11df-950b-00306e48bff7': 'private-job',
    'e2e770be-1f2b-4fa9-a072-c3565da21ed9': 'quick-sets',
    'b2d00a5c-3f28-4370-a703-8f743070e5bc': 'quick-sets',
    '85a462c5-c929-4e18-99cb-269ea1b06974': 'remote-scan-request',
    '34876b06-05be-4044-b61c-40cca9dfe4cb': 'reports',
    'e3b5a909-25ed-4556-8d50-4d7009056d49': 'reprint',
    'faf4e860-6f29-11e6-a8bf-a0481c7e824c': 'scan',
    'd1ac8c3a-9503-44f8-afd8-9fac389f6e32': 'scan',
    '2cd169f1-ad17-4cc1-864e-8f4d5f9ffd10': 'scan-to-cloud',
    '10c9c25c-7b7b-4f7d-b4ad-dd9975be35c7': 'scan-to-computer',
    '0ee1ade3-b5cb-4587-a893-954e77bab7f2': 'scan-to-computer',
    '83969b5e-f2ac-4f8c-a201-a18f02141136': 'scan-to-email-cloud',
    'b8460c9e-43c8-4290-a0f8-8ce450867f09': 'scan-to-email-native',
    '65acca51-619d-4e29-b1d0-6414e52f908b': 'scan-to-nw-folder',
    'a3d696df-b7ff-4d3d-9969-5cd7f18c0c92': 'scan-to-sharepoint',
    '7d0ecb95-f655-41b4-94c8-01ed2da59938': 'scan-to-sharepoint',
    'dc711695-0441-4e1e-b059-6321a40cc6e0': 'scan-to-storage',
    'df4a8a01-7659-486f-95d5-e125ccd1529a': 'scan-to-usb',
    '09866970-7133-404f-bb20-440b9148e8e2': 'scan-to-usb',
    '44aa632b-cfa3-4c10-8cab-697a9bef610b': 'send-fax',
    '1c132a5d-63a5-4c5a-b113-db7945b41670': 'send-later',
    '16ffd151-653a-4a07-8b8f-bb656d43de8c': 'send-now',
    '0e49b040-ed7c-4b11-8dd2-f8acc500760a': 'service',
    '7e755c8d-cdfb-48f5-bbc0-c6873eca99f1': 'service',
    '3dfe6950-5cf9-41c2-a3b2-6154868ab45d': 'settings',
    '13d6829a-0a03-4940-a4ca-3726ac64dad0': 'setup',
    '02016f18-a4b6-4df7-bdea-ea3393fb0886': 'stored-job',
    '3e7cc42a-d7ff-11eb-9ddc-d3f9425e6ff9': 'substrate-library',
    '32fd7d01-7447-4da8-9de5-0bc6eb97f22e': 'supplies',
    'a5e59604-d216-4977-a901-4774fcacbcb4': 'supplies',
    '1d370ecf-fb74-44ae-8934-39740a6911ed:': 'support-tools',
    '12fca0ec-1128-4434-aa4a-68e1ca8c4f42': 'tray-paper-substrate',
    '60ce8d1a-64b1-4850-875b-5b9acfc95963': 'trays',
    'f6d66534-9b96-4f12-9f51-cea0ab19dc79': 'troubleshooting',
    'a9e3da1b-8173-419f-bd6a-2cc325567c4c': 'troubleshooting',
  };

  const findLauncherValue = (key) => {
    return launchers[key] || null;
  };

  return {
    findLauncherValue,
  };
};

export default useSystemLaunchers;
