import { Tooltip } from '@veneer/core';
import React from 'react';

const VersionTooltip = ({ version, tooltip }) => {
const displayedVersion = tooltip ? `${version}...` : version;

  return (
    <div>
      {tooltip ? (
        <Tooltip content={tooltip}  placement = 'bottom' arrow>
          <span>{displayedVersion}</span>
        </Tooltip>
      ) : (
        <span>{version}</span>
      )}
    </div>
  );
};

export default VersionTooltip;