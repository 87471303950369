import React from 'react';
import CheckBoxControl from '../components/Controls/CheckBoxControl';
import DropDownControl from '../components/Controls/DropDownControl';
import FilePickerControl from '../components/Controls/FilePickerControl';
import PasswordControl from '../components/Controls/PasswordControl';
import RadioButtonControl from '../components/Controls/RadioButtonControl';
import TextBoxControl from '../components/Controls/TextBoxControl';
import ToggleControl from '../components/Controls/ToggleControl';
import Dot1xAuthFailureConfiguration from '../components/CustomizableComponents/Dot1xAuthFailureConfiguration/Dot1xAuthFailureConfiguration';
import EwsPasswordConfiguration from '../components/CustomizableComponents/EwsPasswordConfiguration/EwsPasswordConfiguration';
import LdapBindSearchRoot from '../components/CustomizableComponents/LdapBindSearchRoot/LdapBindSearchRoot';
import LdapServerAuth from '../components/CustomizableComponents/LdapServerAuth/LdapServerAuth';
import {
  SubFeatureControlTypeEnum,
  SubFeatureCustomModeEnum,
  SubfeatureIdEnum,
} from '../config/SubFeatureEnums';
import AppDeploymentTable from '../../src/components/CustomizableComponents/AppsDeployment/AppDeploymentTable';

export const BatchSettingsGenerator = () => {
  const generateDeviceSettings = (
    featuresData,
    entitlementArray,
    data,
    id,
    isControlledPolicy,
    ldapSetupCredential,
    isChecked = false,
    isWex
  ) => {
    const deviceSettingsForm = [];
let form: JSX.Element;
    if (data?.customizable && data?.customizable === SubFeatureCustomModeEnum.WRITEVIEW) { 
      if (data.id == SubfeatureIdEnum.Ews_Proposed_Password) {
        form = (
          <EwsPasswordConfiguration
          isWex={isWex}
            featuresId={id}
            device_settings={data}
            isChecked={isChecked}
            isControlledPolicy={isControlledPolicy}
          />
        );
      }
      if (data.id == SubfeatureIdEnum.App_Deployment_AppInstallation) {
        form = (
          <AppDeploymentTable
          featuresId={id}
          device_settings={data.fleetValue}
          settingsName={data.settingsName}
          isChecked = {isChecked}
          attribute={data.attribute}
          isEditView = {true}
           />
        );
      }
      if (data.id == SubfeatureIdEnum.Dot1x_Authentication_OnAuthenticationFailure) {
        form = (
          <Dot1xAuthFailureConfiguration
            featuresId={id}
            device_settings={data}
            isChecked={isChecked}
            isControlledPolicy={isControlledPolicy}
          />
        );
      }
      if (data.id == SubfeatureIdEnum.LDAP_Server_Authentication) {
        form = (
          <LdapServerAuth
            featuresId={id}
            device_settings={data}
            isChecked={isChecked}
            isControlledPolicy={isControlledPolicy}
            isWex = {isWex}
          />
        );
      }
      if (data.id == SubfeatureIdEnum.LDAP_Bind_And_Search_Root) {
        form = (
          <LdapBindSearchRoot
            featuresId={id}
            device_settings={data}
            isChecked={isChecked}
            isControlledPolicy={isControlledPolicy}
            isWex={isWex}
          />
        );
      }
      //bind-prefix
      if (
        data.id == SubfeatureIdEnum.LDAP_Bind_Prefix &&
        (ldapSetupCredential === true || ldapSetupCredential === 'true')
      ) {
        form = (
          <TextBoxControl
            device_settings={data}
            featuresId={id}
            isChecked={isChecked}
            isControlledPolicy={isControlledPolicy}
          />
        );
      }
      //domain-name
      if (
        data.id == SubfeatureIdEnum.LDAP_Admin_DN &&
        (ldapSetupCredential === false || ldapSetupCredential === 'false')
      ) {
        form = (
          <TextBoxControl
            device_settings={data}
            featuresId={id}
            isChecked={isChecked}
            isControlledPolicy={isControlledPolicy}
          />
        );
      }
      //password
      if (
        data.id == SubfeatureIdEnum.LDAP_Admin_DN_Password &&
        (ldapSetupCredential === false || ldapSetupCredential === 'false')
      ) {
        form = (
          <PasswordControl
            featuresData={featuresData}
            entitlement={entitlementArray}
            device_settings={data}
            featuresId={id}
            isControlledPolicy={isControlledPolicy}
          />
        );
      }
    } else {
      switch (data.type) {
        case SubFeatureControlTypeEnum.Textbox:
          form = (
            <TextBoxControl
              device_settings={data}
              featuresId={id}
              isChecked={isChecked}
              isControlledPolicy={isControlledPolicy}
            />
          );
          break;
        case SubFeatureControlTypeEnum.Password:
          form = (
            <PasswordControl
              featuresData={featuresData}
              entitlement={entitlementArray}
              device_settings={data}
              featuresId={id}
              isChecked={isChecked}
              isControlledPolicy={isControlledPolicy}
            />
          );
          break;
        case SubFeatureControlTypeEnum.Dropbox:
          form = (
            <DropDownControl
              device_settings={data}
              featuresId={id}
              isChecked={isChecked}
              isControlledPolicy={isControlledPolicy}
            />
          );
          break;
        case SubFeatureControlTypeEnum.Radio:
          form = (
            <RadioButtonControl
              device_settings={data}
              featuresId={id}
              isChecked={isChecked}
              isControlledPolicy={isControlledPolicy}
            />
          );
          break;
        case SubFeatureControlTypeEnum.Toggle:
          form = (
            <ToggleControl
              device_settings={data}
              featuresId={id}
              isChecked={isChecked}
              isControlledPolicy={isControlledPolicy}
            />
          );
          break;
        case SubFeatureControlTypeEnum.Checkbox:
          form = (
            <CheckBoxControl
              device_settings={data}
              featuresId={id}
              isChecked={isChecked}
              isControlledPolicy={isControlledPolicy}
            />
          );
          break;
        case SubFeatureControlTypeEnum.File:
          form = (
            <FilePickerControl
            isWex={isWex}
              device_settings={data}
              featuresId={id}
              isChecked={isChecked}
              isControlledPolicy={isControlledPolicy}
            />
          );
          break;
      }
    }

    deviceSettingsForm.push({
      form,
    });
    return deviceSettingsForm;
  };

  return { generateDeviceSettings };
};
