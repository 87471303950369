import { Card } from '@veneer/core';
import React from 'react';
import {
  RelatedSettingsCard,
  RelatedSettingsCardContent,
  RelatedSettingsCardDesc,
  RelatedSettingsCardTitle,
  StyledIconInfo,
} from './Styles';
export const InfoNotification = (props) => {
  const { title, message, isWex } = props;

  const content = () => {
    return (
      <RelatedSettingsCard>
        <StyledIconInfo
          id="warning-icon"
          filled={true}
          size={24}
        />
        <RelatedSettingsCardContent>
          {title && <RelatedSettingsCardTitle>{title}</RelatedSettingsCardTitle>}
          <RelatedSettingsCardDesc >{message}</RelatedSettingsCardDesc>
        </RelatedSettingsCardContent>
      </RelatedSettingsCard>
    );
  };


  return (
    <div>
      <Card
        content={content() as any}
        customStyle={{
          borderRadius: '8px',
          marginBottom: '16px',
        }}
      />
    </div>
  );
};
