import {
  ConstraintsResourceEnum,
  ConstraintsSettingsNameEnum,
  ConstraintsSubFeatureIdEnum,
} from '../config/ConstraintsEnums';
import { DeviceCacheCdmDataEnum } from '../config/DeviceCacheCdmDataEnums';
import { DeviceCacheServiceGunEnum } from '../config/DeviceCacheServiceGunEnums';
import { FeatureIdEnum } from '../config/FeatureEnums';
import { FleetSvcPolicyAttributeId } from '../config/FleetServiceEnums';
import {
  SubFeatureAccessModeEnum,
  SubFeatureControlTypeEnum,
  SubfeatureIdEnum,
} from '../config/SubFeatureEnums';

export const WebServicesDataModel = [
  {
    id: `${FeatureIdEnum.Smart_CloudPrint}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Smart_CloudPrint}`,
        attribute: `${FleetSvcPolicyAttributeId.Smart_CloudPrint}.check`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'smartCloudPrint_label',
        info: 'smartCloudPrint_info',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Smart_CloudPrint}`,
        resource: `${ConstraintsResourceEnum.FleetProxyLegacyAttributes}`,
        settingsName: `${ConstraintsSettingsNameEnum.Smart_CloudPrint}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.FleetProxyLegacyAttributes}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.CloudPrint}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.HPJetAdvantage_MoreApps}`,
    configurable: false,
    visible: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.HPJetAdvantage_MoreApps}`,
        attribute: `${FleetSvcPolicyAttributeId.HPJetAdvantage_MoreApps}.check`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'hpJetAdvMoreApps_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.HPJetAdvMoreApps_Enabled}`,
        resource: `${ConstraintsResourceEnum.FleetProxyLegacyAttributes}`,
        settingsName: `${ConstraintsSettingsNameEnum.HPJetAdvantage_MoreApps}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.FleetProxyLegacyAttributes}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.HPJetAdvantage}`,
      },
      {
        id: `${SubfeatureIdEnum.AccountCreation}`,
        attribute: `${FleetSvcPolicyAttributeId.HPJetAdvantage_MoreApps}.create-account`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'accountCreation_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.AccountCreation}`,
        resource: `${ConstraintsResourceEnum.FleetProxyLegacyAttributes}`,
        settingsName: `${ConstraintsSettingsNameEnum.AccountCreation}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.FleetProxyLegacyAttributes}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.AccountCreation}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.HP_WebServices}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.HP_WebServices}`,
        attribute: `${FleetSvcPolicyAttributeId.HP_WebServices}.eprint`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'fe-hp-webservices_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.HP_WebServices}`,
        resource: `${ConstraintsResourceEnum.HP_WebServicesResource}`,
        settingsName: `${ConstraintsSettingsNameEnum.HP_WebServicesSettingsName}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.HP_WebServices}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.HP_WebServicesDCData}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.ProxyServer}`,
    configurable: true,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Proxy_Server}`,
        attribute: `${FleetSvcPolicyAttributeId.Proxy_Server}.check`,
        type: `${SubFeatureControlTypeEnum.Checkbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
        label: 'proxy-server_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.ProxyServerEnabled}`,
        resource: `${ConstraintsResourceEnum.ProxyServer}`,
        settingsName: `${ConstraintsSettingsNameEnum.ProxyServerEnabled}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ProxyServer}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.ProxyServer}`,
      },
      {
        id: `${SubfeatureIdEnum.Proxy_Server_Address}`,
        attribute: `${FleetSvcPolicyAttributeId.Proxy_Server}.address`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE_DISABLE}`,
        label: 'proxy-server-address_label',
        placeholder: 'proxy-server_serverName_placeHolder',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.ProxyServerAddress}`,
        resource: `${ConstraintsResourceEnum.ProxyServer}`,
        settingsName: `${ConstraintsSettingsNameEnum.ProxyServerAddress}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ProxyServer}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.ProxyServer_Address}`,
      },
      {
        id: `${SubfeatureIdEnum.Proxy_Server_Port}`,
        attribute: `${FleetSvcPolicyAttributeId.Proxy_Server}.port`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE_DISABLE}`,
        label: 'proxy-server-port_label',
        placeholder: 'proxy-server_port_placeHolder',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.ProxyServerPort}`,
        resource: `${ConstraintsResourceEnum.ProxyServer}`,
        settingsName: `${ConstraintsSettingsNameEnum.ProxyServerPort}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ProxyServer}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.ProxyServer_Port}`,
      },
      {
        id: `${SubfeatureIdEnum.Proxy_Server_User}`,
        attribute: `${FleetSvcPolicyAttributeId.Proxy_Server}.user`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE_DISABLE}`,
        label: 'proxy-server-user_label',
        placeholder : "proxy-server_userName_placeHolder",
        subFeatureId: `${ConstraintsSubFeatureIdEnum.ProxyServerUser}`,
        resource: `${ConstraintsResourceEnum.ProxyServer}`,
        settingsName: `${ConstraintsSettingsNameEnum.ProxyServerUser}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ProxyServer}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.ProxyServer_User}`,
      },
      {
        id: `${SubfeatureIdEnum.Proxy_Server_Password}`,
        attribute: `${FleetSvcPolicyAttributeId.Proxy_Server}.password`,
        type: `${SubFeatureControlTypeEnum.Password}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE_DISABLE}`,
        label: 'proxy-server-password_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.ProxyServerPassword}`,
        resource: `${ConstraintsResourceEnum.ProxyServer}`,
        settingsName: `${ConstraintsSettingsNameEnum.ProxyServerPassword}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ProxyServer}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.ProxyServer_Password}`,
      },
    ],
  },
];
