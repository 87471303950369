export const fetchDeviceCacheForDemoMode = async (
  apiPath: (arg0: string, arg1: string, arg2: string) => any,
  requestOptions: (arg0: null, arg1: null, arg2: boolean, arg3: boolean) => any,
  mockNoDevices: boolean,
  requestData: string,
): Promise<any> => {
  try {
    if (!mockNoDevices) {
      // Fetch data when mockNoDevices is false
      const response = await fetch(
        apiPath('demo', 'printMfeCache', 'deviceCacheData_deviceConfigMFE'),
        {
          method: 'GET',
          headers: requestOptions(null, null, false, false),
        },
      );
      const data = await response.json();
      const returnData = requestData === 'deviceCache' ? data.deviceCache : [];
      return returnData;
    }
    // If mockNoDevices is true, nothing happens (no data is fetched)
    else return [];
  } catch (e) {
    console.log('deviceConfigMFE > Demo mode  > Error fetching deviceCacheDemoAPI', e);
    return [];
  }
};
export const fetchEffectivePolicyForDemoMode = async (
  apiPath: (arg0: string, arg1: string, arg2: string) => any,
  requestOptions: (arg0: null, arg1: null, arg2: boolean, arg3: boolean) => any,
  mockNoDevices: boolean,
  requestData: string,
): Promise<any> => {
  try {
    if (!mockNoDevices) {
      const response = await fetch(
        apiPath('demo', 'printMfeCache', 'effectivePolicyData_deviceConfigMFE'),
        {
          method: 'GET',
          headers: requestOptions(null, null, false, false),
        },
      );
      const data = await response.json();
      const returnData = requestData === 'effectivePolicy' ? data.effectivePolicy : [];
      return returnData;
    } else return [];
  } catch (e) {
    console.log('deviceConfigMFE > Demo mode  > Error fetching effectivePolicyDemoAPI', e);
    return [];
  }
};
export const fetchContentBrokerForDemoMode = async (
  apiPath: (arg0: string, arg1: string, arg2: string) => any,
  requestOptions: (arg0: null, arg1: null, arg2: boolean, arg3: boolean) => any,
  mockNoDevices: boolean,
  requestData: string,
): Promise<any> => {
  try {
    if (!mockNoDevices) {
      const response = await fetch(
        apiPath('demo', 'printMfeCache', 'contentBrokerData_deviceConfigMFE'),
        {
          method: 'GET',
          headers: requestOptions(null, null, false, false),
        },
      );
      const data = await response.json();
      const returnData = requestData === 'contentBroker' ? data.contentBroker.data : [];
      return returnData;
    }
    // If mockNoDevices is true, nothing happens (no data is fetched)
    else return [];
  } catch (e) {
    console.log('deviceConfigMFE > Demo mode  > Error fetching contentBrokerDemoAPI', e);
    return [];
  }
};
export const fetchAppConfigForDemoMode = async (
  apiPath: (arg0: string, arg1: string, arg2: string) => any,
  requestOptions: (arg0: null, arg1: null, arg2: boolean, arg3: boolean) => any,
  mockNoDevices: boolean,
  requestData: string,
): Promise<any> => {
  try {
    if (!mockNoDevices) {
      const response = await fetch(
        apiPath('demo', 'printMfeCache', 'appConfigData_deviceConfigMFE'),
        {
          method: 'GET',
          headers: requestOptions(null, null, false, false),
        },
      );
      const data = await response.json();
      const returnData = requestData === 'appConfig' ? data.appConfig : [];
      return returnData;
    } else return [];
  } catch (e) {
    console.log('deviceConfigMFE > Demo mode  > Error fetching appConfigDemoAPI', e);
    return [];
  }
};
