import React, { useContext, useEffect, useState } from 'react';
import EditDataContext from '../../../context/EditDataContext';
import CategoryTreeHelper from '../../../helper/CategoryTreeHelper';
import LocalizeHelper from '../../../helper/LocalizeHelper';
import * as Style from './Styles';

const idAll = 'all';
const categorySeparator = '.';

const CategoryTree = (props) => {
  const {
    checkboxFeature,
    setSelectedCategory,
    setSelectedFeatures,
    selectedNode,
    setSelectedNode,
  } = useContext(EditDataContext);
const { isWex } = props;
  // Helpers
  const { getDeviceCategoryLocString } = LocalizeHelper();
  const { getPolicyCategories, filterPolicyAttributes } = CategoryTreeHelper();

  // Localized Strings
  const categoryTitle = getDeviceCategoryLocString('title');
  const categoryAll = getDeviceCategoryLocString('all');

  // UseStates
  const [categoryNodes, setCategoryNodes] = useState([]);

  const checkedCheckbox = checkboxFeature.filter((data) => data.inputDisable === false);

  useEffect(() => {
    const populate = (start) => {
      return getPolicyCategories(start, checkboxFeature).filter((category) => category).map((category) => {
        const startWith = [...start, category];
        const id = [idAll, ...startWith].join(categorySeparator);
        const attributes = filterPolicyAttributes(startWith, checkboxFeature);
        const enabledAttributes = attributes.filter((data) => data.inputDisable === false);

        return {
          id,
          label: getDeviceCategoryLocString(category),
          nodes: populate(startWith),
          totalChildren: enabledAttributes.length ? enabledAttributes.length : null,
        };
      });
    };
    const newNodes = populate([]);
    setCategoryNodes([...newNodes]);
  }, [checkboxFeature]);

  const handleChange = (id) => {
    setSelectedNode(id);
    const attributes = filterPolicyAttributes(
      id.split(categorySeparator).slice(1),
      checkboxFeature,
    );
    setSelectedFeatures(attributes);
    setSelectedCategory(id.split(categorySeparator).slice(1));
  };

  return (
    <Style.CategoryTreeWrap>
      <Style.CategoryTreeTitle>{categoryTitle}</Style.CategoryTreeTitle>
      <Style.CategoryTreeColumn>
        <Style.CustomTreeView
          isWex={isWex}
          className={'vatch-view-custom-tree'}
          defaultExpandedNodes={[idAll]}
          defaultSelectedNodes={[idAll]}
          selectedNodes={[selectedNode]}
          onChange={(e, id) => handleChange(id)}
          nodes={[
            {
              id: idAll,
              label: categoryAll,
              totalChildren: checkedCheckbox.length ? checkedCheckbox.length : null,
              nodes: categoryNodes,
              className: 'batchviewcustomtree',
            },
          ]}
        />
      </Style.CategoryTreeColumn>
    </Style.CategoryTreeWrap>
  );
};
export default CategoryTree;
