import tokens from '@veneer/tokens';
import styled from 'styled-components';
import { Button, Table } from '@veneer/core'
import { styleAttributes11 } from '../../GlobalStyles/GlobalStyles';
import { colorGray2, colorGray5, colorGray7 } from '@veneer/tokens/dist/tokens/tokens';

export const WarningIcon = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colorGray5};
  background: ${colorGray2};
`;

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

export const AppTitle = styled.span`
margin-left: 10px;
font-family: 'Forma DJR Micro';
color: ${colorGray7};
text-align: center;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px;
letter-spacing: 0.32px;
`;
export const TextAlignCenter = styled.span`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const TableWrap = styled.div`
  margin-top: 5px;
  width: 920px;
`;

export const Title = styled.div`
  ${styleAttributes11}
`;

export const CustomButton = styled(Button)`
  height: 20px !important;
  background-color: transparent !important;
`;

export const CustomTable = styled(Table)`
  &.widthColAuto  td {
    max-width: max-content;
  }
`;

export const CustomConfigButton = styled(Button)`
  justify-content: left !important;
  padding-left: 0 !important;
`;
