import {Button} from '@veneer/core';
import styled from 'styled-components';
import { FlexRowSpaceBetween } from '../../GlobalStyles/GlobalStyles';

export const CustomButtonWrap = styled(FlexRowSpaceBetween)`
  width: 100%;
`;

export const CustomCancelButton = styled(Button)`
&:hover {
  background: ${(props) => (props.isWex ? 'rgba(77, 82, 229, 0.1)' : 'rgba(2, 122, 174, 0.1)')};
}
  font-family: 'Forma DJR Micro' !important;
  letter-spacing: 0.02em;
  min-width: 92px !important;
`;

export const CustomSaveButton = styled(Button)`
  font-family: 'Forma DJR Micro' !important;
  letter-spacing: 0.02em;
  min-width: 113px !important;
`;

export const SelectedItemsCount = styled.label`
  padding-left: 24px;
`;
