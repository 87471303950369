import React from 'react';
import CategoryList from '../Category/List/CategoryList';
import CategoryTree from '../CategoryTree/CategoryTree';
import { EditableToggleBatchMode } from '../EditableToggleBatchMode/EditableToggleBatchMode';
import SearchFeature from '../SearchFeature/SearchFeature';
import * as Style from './Styles';

const Body = (props) => {
  const { selectedData , isWex} = props;
  return (
    <Style.ModalBody>
      <Style.ModalBodyRow>
        <Style.CategoryTreeWrap>
          <CategoryTree />
        </Style.CategoryTreeWrap>
        <Style.CategoryWrap>
            <Style.EditFiltersWrap>
              <div>
                <SearchFeature />
              </div>
                <EditableToggleBatchMode />
            </Style.EditFiltersWrap>
          <CategoryList selectedData={selectedData} isWex={isWex} />
        </Style.CategoryWrap>
      </Style.ModalBodyRow>
    </Style.ModalBody>
  );
};

export default Body;
