import React from 'react';
import LocalizeHelper from '../helper/LocalizeHelper';
export const PasswordValidator = (regexData, constraintsData) => {
  let requirementsRegex;
  let withCharacter;
  const minimumVal = parseInt(constraintsData.minimum);
  const maximumVal = parseInt(constraintsData.maximum);
  const constraintDataType = constraintsData.type;
  const {
    getRequirePasswordStrings,
    getRequirePasswordStringsWithCount,
    getRequirePasswordStringsWithRange,
  } = LocalizeHelper();

  withCharacter = minimumVal && minimumVal > 0;

  const getFilteredRegexData = (regexData, removeItems) => {
    return regexData?.filter((v) => !removeItems.includes(v.id));
  };

  const getRequirementsForStringType = (regexData, minimumVal) => {
    const removeItems = ['minimumCharacter', 'maximumCharacter'];
    const newArray = getFilteredRegexData(regexData, removeItems);
    return [
      {
        id: regexData?.find((data) => data.id === 'minimumCharacter')?.id,
        label: getRequirePasswordStringsWithCount('minimumCharacter', minimumVal),
        requirements: newArray?.map((data) => ({
          id: data.id,
          label: getRequirePasswordStrings(data.id),
        })),
      },
    ];
  };

  const getRequirementsForStringTypeWithoutMinVal = (regexData) => {
    return regexData?.filter((data) => data.id !== 'maximumCharacter')?.map((data) => ({
        id: data.id,
        label: getRequirePasswordStrings(data.id),
      }));
  };

  const getRequirementsForNonStringType = (regexData, minimumVal, maximumVal) => {
    const minimumValLength = minimumVal.toString().length;
    return [
      {
        id: regexData?.find((data) => data.id === 'minimumCharacter')?.id,
        label: getRequirePasswordStringsWithCount( minimumValLength == 1 ? 'minimumOneCharacter' : 'minimumCharacter', minimumValLength),
        requirements: [
          {
            id: 'numbers',
            label: getRequirePasswordStringsWithRange('error_range', minimumVal, maximumVal),
          },
        ],
      },
    ];
  };

  const getRequirementsForNonStringTypeWithoutMinVal = (minimumVal, maximumVal) => {
    return [
      {
        id: 'numbers',
        label: getRequirePasswordStringsWithRange('error_range', minimumVal, maximumVal),
      },
    ];
  };

  const setRequirementsRegex = (constraintDataType, regexData, minimumVal, maximumVal) => {
    if (constraintDataType === 'string') {
      if (minimumVal && minimumVal > 0) {
        return getRequirementsForStringType(regexData, minimumVal);
      } else {
        return getRequirementsForStringTypeWithoutMinVal(regexData);
      }
    } else {
      if (minimumVal && minimumVal > 0) {
        return getRequirementsForNonStringType(regexData, minimumVal, maximumVal);
      } else {
        return getRequirementsForNonStringTypeWithoutMinVal(minimumVal, maximumVal);
      }
    }
  };

  requirementsRegex = setRequirementsRegex(constraintDataType, regexData, minimumVal, maximumVal);

  const [errorState, setErrorState] = React.useState(false);
  const [confPassErrorState, setConfPassErrorState] = React.useState(false);
  const [requirementsItems, setRequirementsItems] = React.useState(requirementsRegex);
  const [hide, setHide] = React.useState(true);
  const [passwordError, setPasswordError] = React.useState({
    requirementsErrorTitle: getRequirePasswordStrings('error-message'),
  });
  const [confirmPasswordError, setConfirmPasswordError] = React.useState({
    requirementsErrorTitle: getRequirePasswordStrings('error-message'),
    toggleButtonAriaLabel: '',
  });
  function validatePassword(event) {
    const getRegexById = (regexData, id) => {
      const regexVal = regexData?.find((data) => data.id === id)?.regex
      return regexVal ? new RegExp(regexVal) : regexVal;
    };

    const updateRequirementStatus = (requirements, id, regex, event) => {
      const index = requirements?.findIndex((el) => el.id === id);
      if (regex.test(event)) {
        requirements[index]['status'] = 'complete';
        return false;
      } else {
        requirements[index]['status'] = 'incomplete';
        setPasswordError({ requirementsErrorTitle: getRequirePasswordStrings('error-message') });
        return true;
      }
    };

    const handleCharacterRequirement = (event, copyRequirements, id, regex, withCharacter) => {
      const requirements = withCharacter ? copyRequirements[0].requirements : copyRequirements;
      return updateRequirementStatus(requirements, id, regex, event);
    };

    const setAllStatusIncomplete = (requirements) => {
      requirements.forEach((requirement) => {
        requirement.status = 'incomplete';
        if (requirement.requirements) {
          requirement.requirements.forEach((subRequirement) => {
            subRequirement.status = 'incomplete';
          });
        }
      });
    };

    // Extract regex patterns
    const minimumCharacters = getRegexById(regexData, 'minimumCharacter');
    const maximumCharacters = getRegexById(regexData, 'maximumCharacter');
    const lowerCase = getRegexById(regexData, 'lowercase');
    const upperCase = getRegexById(regexData, 'upperCase');
    const number = getRegexById(regexData, 'numbers');
    const specialCharacter = getRegexById(regexData, 'specialCharacter');

    const copyRequirements = [...requirementsItems];
    let err = false;

    if(event){
      if (minimumCharacters) {
        const index = copyRequirements.findIndex((el) => el.id === 'minimumCharacter');
        if (minimumCharacters.test(event)) {
          copyRequirements[index]['status'] = 'complete';
        } else {
          copyRequirements[index]['status'] = 'incomplete';
          err = true;
        }
      }
      if (maximumCharacters) {
        const index = copyRequirements.findIndex((el) => el.id === 'minimumCharacter');
        if (withCharacter) {
          if (maximumCharacters.test(event)) {
            err = false;
            copyRequirements[index]['label'] = getRequirePasswordStringsWithCount(
              'minimumCharacter',
              minimumVal,
            );
            if (minimumCharacters.test(event)) {
              copyRequirements[index]['status'] = 'complete';
            } else {
              copyRequirements[index]['status'] = 'incomplete';
              err = true;
            }
          } else {
            err = true;
            copyRequirements[index]['label'] = getRequirePasswordStringsWithCount(
              'passwordCharacters',
              maximumVal,
            );
            copyRequirements[index]['status'] = 'error';
          }
        } else {
          if (maximumCharacters.test(event)) {
            err = false;
            const isMaximum = copyRequirements.filter((el) => el.id === 'maximumCharacter');
            if (isMaximum.length) {
              copyRequirements.shift();
            }
          } else {
            err = true;
            const isMaximum = copyRequirements.filter((el) => el.id === 'maximumCharacter');
            if (!isMaximum.length) {
              copyRequirements.unshift({
                id: 'maximumCharacter',
                label: getRequirePasswordStringsWithCount('passwordCharacters', maximumVal),
                status: 'error',
              });
            }
          }
        }
      }

      const requirementsToCheck = [
        { id: 'upperCase', regex: upperCase },
        { id: 'lowercase', regex: lowerCase },
        { id: 'numbers', regex: number },
        { id: 'specialCharacter', regex: specialCharacter },
      ];

      if(lowerCase || upperCase || number || specialCharacter){
        requirementsToCheck.forEach(({ id, regex }) => {
          if (regex) {
            err = handleCharacterRequirement(event, copyRequirements, id, regex, withCharacter) || err;
          }
        });
      }
    }else{
      setAllStatusIncomplete(copyRequirements);
    }

    setErrorState(err);
    setRequirementsItems(copyRequirements);
    return err;
  }

  function validateIntPassword(event) {
    const getRegexById = (regexData, id) => {
      return new RegExp(regexData?.find((data) => data.id === id)?.regex);
    };

    const updateRequirementStatus = (requirements, id, status) => {
      const index = requirements?.findIndex((el) => el.id === id);
      requirements[index]['status'] = status;
    };

    const handleNumberValidation = (event, copyRequirements, minimumVal, maximumVal, withCharacter) => {
      const requirements = withCharacter ? copyRequirements[0].requirements : copyRequirements;
      const index = requirements?.findIndex((el) => el.id === 'numbers');

      if (minimumVal && !maximumVal) {
        if (event >= minimumVal) {
          updateRequirementStatus(requirements, 'numbers', 'complete');
          return false;
        } else {
          updateRequirementStatus(requirements, 'numbers', 'incomplete');
          setPasswordError({ requirementsErrorTitle: getRequirePasswordStrings('error-message') });
          return true;
        }
      }

      if (!minimumVal && maximumVal) {
        if (event <= maximumVal) {
          updateRequirementStatus(requirements, 'numbers', 'complete');
          return false;
        } else {
          updateRequirementStatus(requirements, 'numbers', 'incomplete');
          setPasswordError({ requirementsErrorTitle: getRequirePasswordStrings('error-message') });
          return true;
        }
      }

      if (minimumVal && maximumVal) {
        if (event >= minimumVal && event <= maximumVal) {
          updateRequirementStatus(requirements, 'numbers', 'complete');
          return false;
        } else if (event > maximumVal) {
          updateRequirementStatus(requirements, 'numbers', 'error');
          setPasswordError({ requirementsErrorTitle: getRequirePasswordStrings('error-message') });
          return true;
        } else {
          updateRequirementStatus(requirements, 'numbers', 'incomplete');
          setPasswordError({ requirementsErrorTitle: getRequirePasswordStrings('error-message') });
          return true;
        }
      }

      updateRequirementStatus(requirements, 'numbers', 'incomplete');
      setPasswordError({ requirementsErrorTitle: getRequirePasswordStrings('error-message') });
      return true;
    };

    const setAllStatusIncomplete = (requirements) => {
      requirements.forEach((requirement) => {
        requirement.status = 'incomplete';
        if (requirement.requirements) {
          requirement.requirements.forEach((subRequirement) => {
            subRequirement.status = 'incomplete';
          });
        }
      });
    };

    const minimumCharactersRegex = getRegexById(regexData, 'minimumCharacter');
    const minimumCharacters = new RegExp(minimumCharactersRegex);
    const copyRequirements = [...requirementsItems];
    let err = false;

    if (event.length) {
      if (minimumCharactersRegex) {
        const index = copyRequirements?.findIndex((el) => el.id === 'minimumCharacter');
        if (minimumCharacters.test(event)) {
          copyRequirements[index]['status'] = 'complete';
        } else {
          copyRequirements[index]['status'] = 'incomplete';
          err = true;
        }
      }

      if (/^-?\d+$/.test(event)) {
        err = handleNumberValidation(event, copyRequirements, minimumVal, maximumVal, withCharacter) || err;
      } else {
        updateRequirementStatus(copyRequirements, 'numbers', 'incomplete');
        err = true;
        setPasswordError({ requirementsErrorTitle: getRequirePasswordStrings('error-message') });
      }
    } else {
      setAllStatusIncomplete(copyRequirements);
    }

    setErrorState(err);
    setRequirementsItems(copyRequirements);
    return err;
  }

  function validateConfirmPassword(inputVal, confirmPwdValue, errorMessage) {
    let err = false;
    if (confirmPwdValue.length && inputVal !== confirmPwdValue) {
      err = true;
      setConfirmPasswordError({ requirementsErrorTitle: errorMessage, toggleButtonAriaLabel: '' });
    } else {
      err = false;
    }
    setConfPassErrorState(err);
    return err;
  }

  return {
    validatePassword,
    validateIntPassword,
    validateConfirmPassword,
    errorState,
    confPassErrorState,
    requirementsItems,
    hide,
    setHide,
    passwordError,
    confirmPasswordError,
  };
};
