import { CategoryEnum, CategoryTreeEnum } from '../config/CategoryEnums';
import { FeatureIdEnum } from '../config/FeatureEnums';

export const CategoryTree = () => {
  const catDevice = [CategoryTreeEnum.Device];

  const catDeviceDateAndTime = [CategoryTreeEnum.Device, CategoryTreeEnum.Date_And_Time];

  const catDeviceDefaultPrintMedia = [CategoryTreeEnum.Device, CategoryTreeEnum.DefPrintMedia];

  const catDeviceEnergy = [CategoryTreeEnum.Device, CategoryTreeEnum.Energy];

  const catDeviceGeneral = [CategoryTreeEnum.Device];

  const catSolutions = [CategoryTreeEnum.Solutions];

  const catSecurityDeviceCtrlGen = [
    CategoryEnum.Security
  ];

  const catEws = [CategoryEnum.Ews];

  const catNetwork = [CategoryEnum.Network];

  const catSecurityAuthCred = [
    CategoryEnum.Security
  ];

  const catSupplies = [CategoryEnum.Supplies];

  const catWebServicesProxy = [CategoryTreeEnum.WebServices];
  const policyCategories: {
    id;
    categories;
  }[] = [
    {
      id: FeatureIdEnum.Control_Panel_Language,
      categories: catDevice,
    },
    {
      id: FeatureIdEnum.Date_Time_Format,
      categories: catDeviceDateAndTime,
    },
    {
      id: FeatureIdEnum.Time_Zone,
      categories: catDeviceDateAndTime,
    },
    {
      id: FeatureIdEnum.Default_Media_Size,
      categories: catDeviceDefaultPrintMedia,
    },
    {
      id: FeatureIdEnum.Default_Media_Type,
      categories: catDeviceDefaultPrintMedia,
    },
    {
      id: FeatureIdEnum.Sleep_Delay,
      categories: catDeviceEnergy,
    },
    {
      id: FeatureIdEnum.Asset_Number,
      categories: catDeviceGeneral,
    },
    {
      id: FeatureIdEnum.Company_Name,
      categories: catDeviceGeneral,
    },
    {
      id: FeatureIdEnum.Contact_Person,
      categories: catDeviceGeneral,
    },
    {
      id: FeatureIdEnum.Device_Location,
      categories: catDeviceGeneral,
    },
    {
      id: FeatureIdEnum.Device_Name,
      categories: catDeviceGeneral,
    },
    {
      id: FeatureIdEnum.Duplex_Binding,
      categories: catDeviceGeneral,
    },
    {
      id: FeatureIdEnum.Outgoing_Servers,
      categories: catDeviceGeneral,
    },
    {
      id: FeatureIdEnum.Ews_Language,
      categories: catEws,
    },
    {
      id: FeatureIdEnum.Time_Service,
      categories: catEws,
    },
    {
      id: FeatureIdEnum.Dot1x_Authentication,
      categories: catSecurityAuthCred,
    },
    {
      id: FeatureIdEnum.Ews_Admin_Password,
      categories: catSecurityAuthCred,
    },
    {
      id: FeatureIdEnum.LDAP_SignIn_Setup,
      categories: catSecurityAuthCred,
    },
    {
      id: FeatureIdEnum.PJL_Password,
      categories: catSecurityAuthCred,
    },
    { id: FeatureIdEnum.ProxyServer,
      categories : catWebServicesProxy},
    {
      id: FeatureIdEnum.Remote_Configuration_Password,
      categories: catSecurityAuthCred,
    },
    {
      id: FeatureIdEnum.DHCPv4_FQDN_Compliance_With_RFC_4702,
      categories: catNetwork,
    },
    {
      id: FeatureIdEnum.Dns_Server,
      categories: catNetwork,
    },
    {
      id: FeatureIdEnum.IPv4_information,
      categories: catNetwork,
    },
    {
      id: FeatureIdEnum.IPv6_Information,
      categories: catNetwork,
    },
    {
      id: FeatureIdEnum.Link_Settings,
      categories: catNetwork,
    },
    {
      id: FeatureIdEnum.Airpint_fax,
      categories: catNetwork,
    },
    {
      id: FeatureIdEnum.Airprint_Scan,
      categories: catNetwork,
    },
    {
      id: FeatureIdEnum.Airprint_SecureScan,
      categories: catNetwork,
    },
    {
      id: FeatureIdEnum.SNMP_V1_V2,
      categories: catSecurityAuthCred,
    },
    {
      id: FeatureIdEnum.SNMP_V3,
      categories: catSecurityAuthCred,
    },
    {
      id: FeatureIdEnum.Service_Access_Code,
      categories: catSecurityAuthCred,
    },
    {
      id: FeatureIdEnum.Bt_Low_Energy,
      categories: catSecurityDeviceCtrlGen,
    },
    {
      id: FeatureIdEnum.Cartridge_Policy,
      categories: catSupplies,
    },
    {
      id: FeatureIdEnum.CA_Certificate,
      categories: catNetwork,
    },
    {
      id: FeatureIdEnum.ID_Certificate,
      categories: catNetwork,
    },
    {
      id: FeatureIdEnum.Web_App_Deployment,
      categories: catSolutions,
    },
    {
      id: FeatureIdEnum.Toner_Collection_Unit,
      categories: catSupplies,
    },
    {
      id: FeatureIdEnum.Cartridge_Protection,
      categories: catSupplies,
    },
    {
      id: FeatureIdEnum.Bootloader_Password,
      categories: catSecurityAuthCred,
    },
    {
      id: FeatureIdEnum.Web_App_Deployment,
      categories: catSolutions,
    }
  ];

  return {
    policyCategories,
  };
};
