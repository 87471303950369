import styled from 'styled-components';

export const Wrapper = styled.div`
> nav > ul > li > a > div{
  font-family: 'Forma DJR Micro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
}
@media (max-width: 768px) {
  width: 0%;
  transition: all 1s ease;
}
@media (min-width: 768px) {
  width: 20%;
  transition: all 1s ease;
  position: sticky;
  top: 10px;
  height: 100%;
  padding-top: 10px;
  font-family: 'Forma DJR Micro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #737373;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  width: 228px;
  margin-left: 24px;
  nav {
    left: 0px;
    width: 204px;
  }
`;
