import { Button } from '@veneer/core';
import {Checkbox }from '@veneer/core';
import styled from 'styled-components';
import {
  FlexColumnDirection,
  FlexRowAlignCenter,
  styleAttributes3,
  styleAttributes5,
} from '../../../GlobalStyles/GlobalStyles';

export const FlexRow = styled.div`
  display: flex;
`;

export const EditFeatureHeaderWrap = styled(FlexColumnDirection)``;

export const CategoryLevel = styled(FlexRow)`
  ${styleAttributes5}
  margin-top: '4px';
`;

export const EditFeatureHeader = styled(FlexRowAlignCenter)``;

export const EditFeatureIcon = styled(FlexRow)``;

export const FeatureTitleWrap = styled(FlexRowAlignCenter)``;

// Start
export const FeatureTitle = styled.div`
  ${styleAttributes3}
`;

export const FeatureTitleCheckBox = styled(Checkbox)`
  padding-right: 4px;
  display: flex;
  align-items: flex-start;
  .vn-checkbox__span {
    margin-top: 6px;
  }
`;

export const DecorateNameWrap = styled.span`
  background-color: ${(props) => props.color};
`;

export const InfoButton = styled(Button)`
&:hover{
  background: ${(props) => (props.isWex ? 'rgba(77, 82, 229, 0.1)' : 'rgba(2, 122, 174, 0.1)')};
}
padding: 4px !important;
border: 0px !important;
`;
