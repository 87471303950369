export enum CategoryEnum {
  Copier = 'copier',
  Device = 'device',
  Ews = 'ews',
  Network = 'network',
  Security = 'security',
  Supplies = 'supplies',
  Solutions = 'solutions',
  DigitalSend = 'digitalSend',
  WebServices = 'webServices',
  Wireless = 'wireless',
}

export enum CategoryTreeEnum {
  Device = 'device',
  Date_And_Time = 'date-and-time',
  DefPrintMedia = 'def_print_media',
  Energy = 'energy',
  General = 'general',
  Ews = 'ews',
  Network = 'network',
  Security = 'security',
  Authentication = 'authentication',
  Credentials = 'credentials',
  DeviceControl = 'device-control',
  StoredData = 'stored-data',
  ControlPanel = 'control-panel',
  ExternalConnection = 'external-connection',
  DeviceSecurityChecks = 'device-security-checks',
  DeviceDiscovery = 'device-discovery',
  NetworkSecurity = 'network-security',
  NetworkServices = 'network-services',
  Web = 'web',
  Printing = 'printing',
  Supplies = 'supplies',
  Sleep_Delay = 'sleep-delay',
  Solutions = 'solutions',
  DigitalSend = 'digitalSend',
  Wireless = 'wireless',
  WebServices = 'webServices',
}
