import tokens from '@veneer/tokens';
import { colorGray4, colorGray7, fontSize3, lineHeight3 } from '@veneer/tokens/dist/tokens/tokens';
import styled from 'styled-components';
import { theme } from '../GlobalStyles/GlobalStyles';
import { Toggle } from '@veneer/core';
import { Button } from '@veneer/core';

export const CustomToggle = styled(Toggle)`
  > span.vn-toggle__span-text {
    max-width: calc(100% - 32px) !important;
  }
  .vn-toggle {
    border-radius: calc(10px) !important;
    height: 20px !important;
    width: calc(32px) !important;
  }
  > span.vn-toggle::before {
    left: ${(props) => props.on && 'calc(100% - 7px) '} !important;
    height: 12px !important;
    width: 12px !important;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  width: 400px;
  padding-bottom: 8px;
`;

export const PasswordField = styled.div`
  display: flex;
  width: 400px;
  border-radius: 8px;
`;

export const ConfirmPasswordField = styled(PasswordField)``;

export const TextBlack = styled.div`
  font-family: ${theme.fontFamilyPrimary};
  font-size: ${fontSize3};
  line-height: ${lineHeight3};
  letter-spacing: 0.02em;
  color: ${(props) => (props.disable ? colorGray4 : colorGray7)};
`;

export const ConfirmPasswordBlock = styled.div``;

export const InputWrap = styled.div`
  width: 400px;
  margin-bottom: 8px;
  &:last-child {
    width: 400px;
    margin-bottom: 0px;
    @media (max-width: 991px) {
      width: 100%;
    }
  }
  @media (max-width: 991px) {
    width: 100%;
  }
`;

export const FullWidthInputWrap = styled.div`
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  &:last-child {
    width: 100%;
    margin-bottom: 0px;
    @media (max-width: 991px) {
      width: 100%;
    }
  }
  @media (max-width: 991px) {
    width: 100%;
  }
`;

export const ToggleInputWrap = styled.div`
  width: 100%;
  margin-bottom: 16px;
  &:last-child {
    width: 100%;
    margin-bottom: 0px;
    @media (max-width: 991px) {
      width: 100%;
    }
  }
  @media (max-width: 991px) {
    width: 100%;
  }
`;

export const FileNameLabel = styled.div`
  font-size: ${tokens.fontSize3};
  line-height: ${tokens.lineHeight3};
  font-family: ${tokens.fontFamilyRegular};
  color: ${(props) => (props.disable ? colorGray4 : colorGray7)};
  overflow-wrap: anywhere;
  margin-left: 12px;
`;

export const FilePickerContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FilePickerInput = styled.input`
  display: none;
`;
export const StyledButton = styled(Button)`
  &:hover {
    background: ${(props) =>
      props.isWex ? 'rgba(77, 82, 229, 0.1)' : 'rgba(2, 122, 174, 0.1)'};
  }
  span {
    font-family: 'Forma DJR Micro' !important;
  }
  ${(props) =>
    props.isWex &&
    `width: 100px;
   height: 36px ;
   padding: 8px 20px 8px 20px;
   gap: 8px;
   border-radius: 360px !important;
   border: 1px;
   opacity: 0px;`}
   min-width: 116px !important;
  height: 36px;
`;

export const FilePickerError = styled.div`
  margin-top: 4px;
  min-width: 130px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const FilePickerErrorMessage = styled.span`
  color: rgb(33, 33, 33);
  font-size: 14px;
  line-height: 20px;
  font-family: ${tokens.fontFamilyRegular};
`;

export const InputWrapHeading = styled.span`
  color: #404040;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  font-family: ${tokens.fontFamilyRegular};
  margin-bottom: 16px;
  display: block;
`;
