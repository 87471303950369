import { Button, ColumnIndexTypes, CustomImage, Table } from '@veneer/core';
import React, { useEffect, useMemo, useState, useContext } from 'react';
import LocalizeHelper from '../../../helper/LocalizeHelper';
import { AppTitle, FlexRow, Title, TableWrap, CustomTable, CustomConfigButton } from './Styles';
import AppDeploymentWithoutApps from './AppDeploymentWithoutApps';
import { MicroFrontend } from '../../../components/ReadView/MicroFrontend';
import EditDataContext from '../../../context/EditDataContext';
import VersionTooltip from '../../CustomizableComponents/AppsDeployment/VersionTooltip'; // Import the VersionTooltip component

const AppDeploymentTable = (props) => {
  const { isEditView, featuresId, device_settings, settingsName, attribute, isChecked } = props;
  const { getCustomizableComponentStrings, getDeviceSettingsLocString, getCommonStrings } = LocalizeHelper();
  const [showMode, setShowMode] = useState(false);
  const [selectIndex, setSelectIndex] = useState(null);
  const [appsData, setAppsData] = useState([])
  const [appsFmsData, setAppsFmsData] = useState([])
  const [isDisable, setIsDisable] = useState(isChecked);
  
  useEffect(() => {
    setIsDisable(isChecked);
  }, [isChecked]);

  const editDataContext = useContext(EditDataContext);
  let onUpdateTempData;

  if (isEditView) {
    onUpdateTempData = editDataContext.onUpdateTempData;
  }

  enum AppDeploymentEnum {
    NAME = 'name',
    ICON = 'icon',
    VERSION = 'version',
    PROVIDER = 'provider',
    CONFIG = 'config',
  }

  const tableColumns = useMemo(() => {
    const id = 'id';
    const index: ColumnIndexTypes = 'hidden';

    return [
      { id, label: id, index },
      { id: AppDeploymentEnum.NAME, label: getCustomizableComponentStrings('webAppName') },
      { id: AppDeploymentEnum.VERSION, label: getCustomizableComponentStrings('webAppVersion') },
      {
        id: AppDeploymentEnum.PROVIDER,
        label: getCustomizableComponentStrings('webAppProvider'),
      },
      {
        id: AppDeploymentEnum.CONFIG,
        label: isEditView? getCustomizableComponentStrings('webAppConfiguration') : null,
        index,
      },
    ];
  }, []);

  const tablePreferences = {
    width: [
      { columnId: AppDeploymentEnum.NAME, width: 25 },
      { columnId: AppDeploymentEnum.VERSION, width: 3 },
      { columnId: AppDeploymentEnum.PROVIDER, width: 3 },
      { columnId: AppDeploymentEnum.CONFIG, width: 69 },
    ],
  };

  const policiesAccordionContent = () => {
    const result = appsFmsData?.filter((setting, index) => index === selectIndex);
    const selectedData = appsData?.filter((setting, index) => index === selectIndex);
    const selectedDataConfig = selectedData[0]?.config?.props?.children;

    const isSet = typeof selectedDataConfig === 'string' && selectedDataConfig.toLowerCase() === 'edit';

    const appConfig = {
      appId: result[0]?.resourceId,
      mocId: result[0]?.mocId,
      header: <FlexRow className={'alignCenter'}>
        <CustomImage size={24} src={result[0].appIcon} />
        <AppTitle>{result[0].title}</AppTitle>
      </FlexRow>,
      config: isSet ? result[0]?.config : isSet,
      onClose: () => handleOnClose(),
      onChange: (config) => {
        handleOnSave(config);

        const updatedResult = result.map((item, index) => {
          if (index === 0) {
            return {
              ...item,
              config: [config],
            };
          }
          return item;
        });

        setAppsFmsData((prevData) => {
          const newData = [...prevData];
          newData[selectIndex] = updatedResult[0];
          return newData;
        });
      }
    }

    if(result[0]?.resourceId && result[0]?.mocId){
      return (
        <MicroFrontend
          disableContainerPadding
          type={'ecpPolicies'}
          component={'@jarvis/react-ecp-policies'}
          appConfig={appConfig}
          localization={{
            language: 'en',
            country: 'US',
          }}
        />
      )
    }
  }

  useEffect(() => {
    let appsData = []

    if (Array.isArray(device_settings)) {
      appsData = device_settings?.map((x, index) => {
        const commonData = {
          [AppDeploymentEnum.NAME]: (
            <FlexRow className={'alignCenter'}>
              <CustomImage size={27} src={x.appIcon} />
              <AppTitle>{x.title}</AppTitle>
            </FlexRow>
          ),
          [AppDeploymentEnum.VERSION]:  (
            <VersionTooltip
             version={x.version} tooltip={x.tooltip} /> // Use VersionTooltip component
          ),
          [AppDeploymentEnum.PROVIDER]: x.provider,
        };

        if (isEditView) {
          return {
            ...commonData,
            [AppDeploymentEnum.CONFIG]: (
              device_settings?.filter((app) => app.uuid === x.uuid)[0]?.mocId ?
               <CustomConfigButton appearance="ghost" onClick={() => handleOnClick(index)} customStyle={{ textAlign: 'left' }} disabled={isDisable} >
              {getCommonStrings('set')}
            </CustomConfigButton> : null
            ),
          };
        }

        return commonData;
      });
    } else {
      console.error('device_settings is not an array:', device_settings);
    }

    setAppsData(appsData);
    setAppsFmsData(device_settings)

  }, [device_settings, isEditView, isDisable]);

  function getValueAtIndex(data, index) {
    return data[index];
  }

  function changeConfigChildrenValue(obj, newValue) {
    return {
      ...obj,
      config: {
        ...obj.config,
        props: {
          ...obj.config.props,
          children: newValue
        }
      }
    };
  }

  const handleOnClose = () =>{
    setShowMode(false)
  }

  const handleOnClick = (index) => {
    setShowMode(!showMode);
    setSelectIndex(index);
  };

  const handleOnSave = (_data) => {
    setShowMode(!showMode);
    // Create a copy of the appsFmsData array
  let appsFmsDataCopy = [...appsFmsData];

  // Use map to replace the value at the selectIndex and retain other values
  const result = appsFmsDataCopy?.map((setting, index) => {
    if (index === selectIndex) {
      return {
        ...setting,
        config: [_data],
      };
    }
    return setting;
  });

    setAppsFmsData(result)

    if(selectIndex !== null){
      const newObj = changeConfigChildrenValue(getValueAtIndex(appsData, selectIndex), getCommonStrings('edit'));
      let appsDataCopy =  appsData
      appsDataCopy[selectIndex] = newObj;
      setAppsData(appsDataCopy)
    }

    if(isEditView){
      onUpdateTempData({
        settingsName: settingsName,
        fleetValue: result,
        attribute: attribute,
        error: false,
      });
    }
  };

  if(appsData.length === 0 && isEditView) {
    return <AppDeploymentWithoutApps />;
  }

  return <>
    {isEditView ? <Title>{featuresId ? getDeviceSettingsLocString(featuresId, 'apps_configuration_label') : null}</Title> : null}
    <TableWrap><CustomTable columns={tableColumns} data={appsData} preferences={tablePreferences} className={'widthColAuto'}/></TableWrap>
    {showMode ? policiesAccordionContent() : null}

  </>
};

export default AppDeploymentTable;
