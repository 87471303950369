export const anchorMenuContainerId = '@jarvis/react-ecp-device-details';

const environmentVariables = {
  local: {
    LDClientId: '61a5e1c8df185e111101eb6c'
  },
  dev: {
    LDClientId: '61a5e1c8df185e111101eb6c'
  },
  pie: {
    LDClientId: '61a5e1c8c51bb81118631a45'
  },
  stage: {
    LDClientId: '61a5e1c89ef8801188f3447e'
  },
  prod: {
    LDClientId: '61a5e1c82e5f1210de97acb4'
  }
}

export function getEnvConfig(env) {
  const tlcEnv = env instanceof String ? env.toLowerCase() : env
  switch (tlcEnv) {
    case 'local':
      return environmentVariables.local
    case 2:
    case 'stage':
    case 'stg':
      return environmentVariables.stage
    case 3:
    case 'prod':
    case 'production':
      return environmentVariables.prod
    case 1:
    case 'pie':
      return environmentVariables.pie
    case 0:
    case 'dev':
      return environmentVariables.dev
    default:
      return environmentVariables.pie
  }
}

export const fetchLDClientSideID = () => {
  const host = window.location.hostname

  return {
    localhost: getEnvConfig(0).LDClientId,
    'ecp.local.portalshell.int.hp.com': getEnvConfig(1).LDClientId,
    'ecp.dev.portalshell.int.hp.com': getEnvConfig(0).LDClientId,
    'ecp.pie.portalshell.int.hp.com': getEnvConfig(1).LDClientId,
    'usdevms-workforce.hppipeline.com': getEnvConfig(1).LDClientId,
    'ecp.stage.portalshell.int.hp.com': getEnvConfig(2).LDClientId,
    'usstagingms.workforceexperience.hp.com': getEnvConfig(2).LDClientId,
    'hp-commandcenter.com': getEnvConfig(3).LDClientId,
    'workforceexperience.hp.com': getEnvConfig(3).LDClientId,
  }[host]
}

export const service = {
  fleetMgtV: 'fleetMgt',
  fleetMgtV1: 'fleetMgtV1',
  fleetMgtV2: 'fleetMgtV2',
  constraint: 'constraint',
};

export const ews_password_field = {
  check: 'ews-password.check',
  value: 'ews-password.value',
};

// # request body for user-defined-password
export const remote_cfg_field = {
  check: 'remote-cfg-password.check',
  value: 'remote-cfg-password.value',
  minLength: 'remote-cfg-password.min-length',
  complexity: 'remote-cfg-password.complexity',
  lockout: 'remote-cfg-password.lockout',
  maxAttempts: 'remote-cfg-password.max-attempts',
  resetAfter: 'remote-cfg-password.reset-after',
  lockoutDurations: 'remote-cfg-password.lockout-duration',
};

export const snmp_v1_v2_field = {
  permission: 'snmp-v1-v2.permission',
  read: 'snmp-v1-v2.read-password',
  readWrite: 'snmp-v1-v2.read-write-password',
  defPublic: 'snmp-v1-v2.def-public',
};

export const snmp_v3_field = {
  check: 'snmp-v3.check',
  passwordComplexity: 'snmp-v3.complexity',
  userName: 'snmp-v3.user-name',
  authPassphrase: 'snmp-v3.auth-passphrase',
  privacyPassphrase: 'snmp-v3.privacy-passphrase',
  // encryption: 'snmp-v3.encryption-algorithm',
  lockout: 'snmp-v3.lockout',
  maxAttempts: 'snmp-v3.max-attempts',
  resetAfter: 'snmp-v3.reset-after',
  lockoutDuration: 'snmp-v3.lockout-duration',
};

export const service_access_code_field = {
  access: 'svc-access-code.value',
};

export const setting = (_name: string, _value: any) => {
  return { name: _name, value: _value };
};

export const defaultAnchorPosition: any = {
  position: 'relative',
  top: 0,
  bottom: 0,
  end: 0,
  left: 16,
  right: 0,
  start: 0,
};

export const setting_type = {
  endpoint_ews_pw: 'endpoint_ews_pw',
  remote_config_pw: 'remote_config_pw',
  secure_access_code: 'secure_access_code',
  snmp_v1_v2_pw: 'snmp_v1_v2_pw',
  snmp_v3_pw: 'snmp_v3_pw',
};

export const store = {
  config: {
    START_ROOT_COMPONENT: 'config/startRootComponent',
    DEVICE_ID: 'config/deviceId',
    DEVICE_MODEL_NUMBER: 'config/deviceModelNumber',
    SHOW_MULTIPLE_CONFIG_UI: 'config/showMultipleConfigUi',
    SELECTED_DEVICE_ID_LIST: 'config/selectedDeviceIdList',
  },
  configureDevices: {
    SETTINGS_LIST: 'configureDevices/settingsList',
    ALLOWED_SETTINGS_LIST: 'configureDevices/allowedSettingsList',
    SELECTED_SETTING: 'configureDevices/selectedSetting',
  },
};

export const setting_label = {
  endpoint_ews_pw: 'EWS Admin Password',
  remote_config_pw: 'Remote Config Password',
  secure_access_code: 'Service AccessCode',
  snmp_v1_v2_pw: 'SNMP V1/V2 Password',
  snmp_v3_pw: 'SNMP V3 Password',
};

export const categoryStore = {
  config: {
    SAVE_PLUGIN_NAME: 'savePluginName',
    SET_RULES_DATA: 'setRulesData',
    SET_RULES_DATA_UPDATE: 'setRulesDataUpdate',
    GET_RULES_EFFECT: 'getRulesEffect',
    SET_POLICY_DATA: 'setPolicyData',
    SET_FEATURES_DATA: 'setFeaturesData',
    SET_SETTINGS_DATA: 'setSettingsData',
    SET_EDITVIEW_DATA: 'setEditViewData',
    UPDATE_EDITVIEW_DATA: 'updateEditViewData',
    SET_TEMP_FEATURE: 'setTempFeature',
    SET_TEMP_FEATURE_UPDATE: 'setTempFeatureUpdate',
    SET_TEMP_RULES: 'setTempRules',
    SET_TEMP_RULES_UPDATE: 'setTempRulesUpdate',
    SET_TEMP_RULES_EFFECT: 'setRulesEffect',
    RESET_INITIAL_DATA: 'resetInitialData',
    SET_BATCH_RULES_DATA: 'setBatchRulesData',
    SET_BATCH_POLICY_DATA: 'setBatchPolicyData',
    SET_BATCH_FEATURES_DATA: 'setBatchFeaturesData',
    SET_BATCH_SETTINGS_DATA: 'setBatchSettingsData',
    RESET_BATCH_INITIAL_DATA: 'resetBatchInitialData',
    SET_TEMP_BATCH_DATA: 'setTempBatchData',
    SET_REQUEST: 'setRequest',
    SET_CONTROLLED_POLICY_REQUEST: 'setControlledPolicyRequest',
    SET_CONTROLLED_POLICY: 'setControlledPolicy',
    SET_CONTROLLED_POLICY_ERROR: 'setControlledPolicyError',
    SET_DEVICECONFIG_DATA: 'setDeviceConfigData',
    SET_DEVICECONFIG_SETTINGS_DATA: 'setDeviceConfigSettingsData',
  },
};

export const defaultNodes = [
  {
    id: 'All',
    label: 'Security',
    totalChildren: 4,
    nodes: [
      {
        id: setting_type.endpoint_ews_pw,
        label: 'EWS Admin Password',
      },
      {
        id: setting_type.snmp_v1_v2_pw,
        label: 'SNMP V1/V2 Password',
      },
      {
        id: setting_type.snmp_v3_pw,
        label: 'SNMP V3 Password',
      },
      {
        id: setting_type.secure_access_code,
        label: 'Service AccessCode',
      },
    ],
  },
];
