import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fleetMgtSvc } from '../../api/FleetMgtSvc';
import apiController from '../../api/apiController';
import { FeatureEffectivePolicyEnum } from '../../config/FeatureEnums';
import { FleetSvcCustomValue, FleetSvcPolicyAttributeId } from '../../config/FleetServiceEnums';
import { SubFeatureAccessModeEnum } from '../../config/SubFeatureEnums';
import EditDataContext from '../../context/EditDataContext';
import ConstraintsRuleParser from '../../dataParser/ConstraintsRuleParser';
import CustomValidationHelper from '../../helper/CustomValidationHelper';
import { FleetAttributeHelper } from '../../helper/FleetAttributeHelper';
import LocalizeHelper from '../../helper/LocalizeHelper';
import TempDataHelper from '../../helper/TempDataHelper';
import { shellProps } from '../../shellProps';
import { setRulesEffectData } from '../../store/deviceConfig/action';
import { useStoreState } from '../../store/useStoreState';
import { service } from '../../utils/constants';
import Body from './Body/Body';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import * as Style from './Styles';

const BatchView = (props) => {
  let flagSuccess = 0;
  let flagError = 0;
  const { selectedData, isWex } = props;
  const { getDeviceModalLocString } = LocalizeHelper();

  const { deviceConfigDataState } = useStoreState();
  const { featuresData, settingsData, rulesData, controlPolicyData } = deviceConfigDataState;

  const idAll = 'all';
  const [isFetching, setIsFetching] = useState(false);
  const [offset, setOffset] = useState(0);
  const [checkboxCount, setcheckboxCount] = useState(0);
  const [checkboxFeature, setcheckboxFeature] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [tempSettings, setTempSettings] = useState([]);
  const [tempRulesData, setTempRulesData] = useState([]);
  const [tempRulesEffect, setTempRulesEffect] = useState([]);
  const [editViewData, setEditViewData] = useState([]);
  const [enable, setEnable] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [checkedFeaturesToggle, setCheckedFeaturesToggle] = useState(false);
  const [selectedCategoryName, setSelectedCategoryName] = useState('');
  const [selectedNode, setSelectedNode] = useState(idAll);
  const [customErrorData, setCustomErrorData] = useState({ name: '', error: false, errorMsg: '' });

  const dispatch = useDispatch();

  const { selectedDeviceIdList, showModal, setShowModal, handleUseToast, showMultipleConfigUi } =
    useContext(shellProps);
  const { getApiClient } = apiController();
  const client = getApiClient(service.fleetMgtV2);
  const clientV1 = getApiClient(service.fleetMgtV1);
  const { getUpdateTempData, getEditViewData, getUpdatedCheckedFeatures } = TempDataHelper();
  const { setRuleEffect } = ConstraintsRuleParser();
  const { getCustomValidate } = CustomValidationHelper(editViewData, setCustomErrorData);

  const onConfigure = async () => {
    setIsFetching(true);
    const selectedSettings = editViewData
      .filter((data) =>
        data.name !== FleetSvcCustomValue.PUBLIC
          ? data.isChecked && !data.rulesEffect
          : data.isChecked,
      )
      .filter((data) => data.isFmsPayload === true || data.isFmsPayload === undefined);

    const editData = selectedSettings.map((item) => {
      const payload = FleetAttributeHelper(item);

      return payload;
    });

    let finaleditViewDataCopy = [...editData];

    const ldapSetupCredential = finaleditViewDataCopy.find(
      (data) => data.name === 'ldap-setup.credential',
    );

    if (
      ldapSetupCredential &&
      (ldapSetupCredential.value === 'true' ||
        ldapSetupCredential.value === 'ldap-setup.credential.device-user')
    ) {
      finaleditViewDataCopy = finaleditViewDataCopy.filter(
        (data) => data.name !== 'ldap-setup.domain-name' && data.name !== 'ldap-setup.password',
      );
    }

    if (
      ldapSetupCredential &&
      (ldapSetupCredential.value === 'false' ||
        ldapSetupCredential.value === 'ldap-setup.credential.admin')
    ) {
      finaleditViewDataCopy = finaleditViewDataCopy.filter(
        (data) => data.name !== 'ldap-setup.bind-prefix',
      );
    }

    const isSnmpv1v2Option = finaleditViewDataCopy?.filter((data) => {
      return data.name === FleetSvcCustomValue.PERMISSION;
    });

    if (isSnmpv1v2Option.length && isSnmpv1v2Option[0].value === FleetSvcCustomValue.DISABLE) {
      finaleditViewDataCopy = [...editData]?.filter((data) => {
        return data.name !== FleetSvcCustomValue.PUBLIC;
      });
    }

    if (isSnmpv1v2Option.length && isSnmpv1v2Option[0].value === FleetSvcCustomValue.READONLY) {
      const snmpv1v2DefPublic = finaleditViewDataCopy?.find((data) => {
        return data.name === FleetSvcCustomValue.PUBLIC;
      });
      snmpv1v2DefPublic.value = true;
    }

    const certificateDataIds = [
      `${FleetSvcPolicyAttributeId.CA_Certificate}.file`,
      `${FleetSvcPolicyAttributeId.ID_Certificate}.file`,
    ];

    const certificateData = finaleditViewDataCopy.filter((_data) =>
      certificateDataIds.includes(_data['name']),
    );

    if (certificateData.length && certificateData.length == 2) {
      const caCertificateData = certificateData.find(
        (_data) => _data.name === 'ca-certificate.file',
      );
      const idCertificateData = certificateData.find(
        (_data) => _data.name === 'id-certificate.file',
      );
      const caCertificateStoredFile = await fleetMgtSvc.getCertificateDetails(
        clientV1,
        caCertificateData.value.filename,
        caCertificateData.value.filetype,
        caCertificateData.value.contents,
      );

      const idCertificateStoredFile = await fleetMgtSvc.getCertificateDetails(
        clientV1,
        idCertificateData.value.filename,
        idCertificateData.value.filetype,
        idCertificateData.value.contents,
      );

      if (caCertificateStoredFile.response !== undefined) {
        const caCertificatePayload = `{"id":"${caCertificateStoredFile.response.data.id}", "name":"${caCertificateData.value.filename}"}`;
        finaleditViewDataCopy.find((v) => v.name === caCertificateData.name).value =
          caCertificatePayload;
      } else {
        finaleditViewDataCopy.splice(
          finaleditViewDataCopy.findIndex((v) => v.name === caCertificateData.name),
          1,
        );
      }

      if (idCertificateStoredFile.response !== undefined) {
        const idCertificatePayload = `{"id":"${idCertificateStoredFile.response.data.id}", "name":"${idCertificateData.value.filename}"}`;
        finaleditViewDataCopy.find((v) => v.name === idCertificateData.name).value =
          idCertificatePayload;
      } else {
        finaleditViewDataCopy.splice(
          finaleditViewDataCopy.findIndex((v) => v.name === idCertificateData.name),
          1,
        );
        finaleditViewDataCopy.splice(
          finaleditViewDataCopy.findIndex((v) => v.name === 'id-certificate.password'),
          1,
        );
      }

      if (
        caCertificateStoredFile.error !== undefined &&
        idCertificateStoredFile.error !== undefined
      ) {
        setShowModal(false);
        renderError();
      } else {
        const configure = await fleetMgtSvc.useConfigureDevice(
          client,
          selectedDeviceIdList,
          finaleditViewDataCopy,
        );
        if (configure.response !== undefined) {
          setShowModal(false);
          renderSuccess(configure.response.data.remediations);
        }
        if (configure.error !== undefined) {
          setShowModal(false);
          renderError();
        }
      }
    } else if (certificateData.length && certificateData.length < 2) {
      const storedFile = await fleetMgtSvc.getCertificateDetails(
        clientV1,
        certificateData[0].value.filename,
        certificateData[0].value.filetype,
        certificateData[0].value.contents,
      );
      if (storedFile.error !== undefined) {
        setShowModal(false);
        renderError();
      }

      const payload = `{"id":"${storedFile.response.data.id}", "name":"${certificateData[0].value.filename}"}`;

      finaleditViewDataCopy.find((v) => v.name === certificateData[0].name).value = payload;

      const configure = await fleetMgtSvc.useConfigureDevice(
        client,
        selectedDeviceIdList,
        finaleditViewDataCopy,
      );

      if (configure.response !== undefined) {
        setShowModal(false);
        renderSuccess(configure.response.data.remediations);
      }
      if (configure.error !== undefined) {
        setShowModal(false);
        renderError();
      }
    } else {
      const configureDevice = async (deviceId) => {
        const configure = await fleetMgtSvc.useConfigureDevice(
          client,
          [deviceId],
          finaleditViewDataCopy,
        );
        if (configure.response !== undefined) {
          flagSuccess++;
        }
        if (configure.error !== undefined) {
          flagError++;
        }
      };
      await Promise.all(selectedDeviceIdList.map(configureDevice));

      if (flagSuccess > 0) {
        setShowModal(false);
        handleUseToast({
          id: 'success',
          type: 'informative',
          text: getDeviceModalLocString('batch-mode-temporary-save-wait-notification-toast-title', {
            variable: flagSuccess,
          }),
          subtitle: getDeviceModalLocString(
            'single-mode-temporary-save-wait-notification-toast-subtitle',
          ),
        });
      }

      if (flagError > 0) {
        setShowModal(false);
        handleUseToast({
          id: 'error',
          type: 'negative',

          text: getDeviceModalLocString('batch-mode-temporary-save-negative-toast', {
            variable: flagError,
          }),

          action: <a onClick={() => onConfigure()}> {getDeviceModalLocString('retry-toast')}</a>,
        });
      }

      setIsFetching(false);
    }
  };

  const onClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    dispatch(setRulesEffectData(setRuleEffect(rulesData)));
  }, [rulesData]);

  useEffect(() => {
    const filteredFeature = featuresData.map((features) => {
      return { ...features, inputDisable: true };
    });
    setcheckboxFeature(filteredFeature);

    setTempSettings(settingsData);
    setTempRulesData(rulesData);
    setTempRulesEffect(setRuleEffect(rulesData));
    setEditViewData(getEditViewData(setRuleEffect(rulesData), settingsData, showMultipleConfigUi));
  }, []);

  useEffect(() => {
    const filteredSettingsData = featuresData
      .map((features) => {
        const policySettings = features.subfeatures.map((data) => {
          return data;
        });
        const result =
          features.effectivePolicy &&
          features.effectivePolicy === FeatureEffectivePolicyEnum.controlledPolicy
            ? policySettings.filter(
                (data) => data.accessMode !== SubFeatureAccessModeEnum.READWRITE_DISABLE,
              )
            : policySettings;
        return result;
      })
      .flat(1);

    const filteredFeature = featuresData.map((features) => {
      return { ...features, inputDisable: true };
    });
    setcheckboxFeature(filteredFeature);

    setEditViewData(
      getEditViewData(setRuleEffect(rulesData), filteredSettingsData, showMultipleConfigUi),
    );
    setTempSettings(filteredSettingsData);
    setTempRulesData(rulesData);
    setTempRulesEffect(setRuleEffect(rulesData));
  }, [controlPolicyData]);

  const onUpdateTempData = (payload) => {
    const updatedTempData = getUpdateTempData(
      payload,
      tempSettings,
      tempRulesData,
      editViewData,
      showMultipleConfigUi,
    );

    setTempSettings(updatedTempData.updatedTempFeature);
    setTempRulesData(updatedTempData.updatedTempRulesData);
    setTempRulesEffect(updatedTempData.updatedTempRulesEffectData);
    setEditViewData(updatedTempData.updatedEditViewData);
  };

  const renderSuccess = (remediationIdList) => {
    handleUseToast({
      id: 'success',
      type: 'positive',
      text: getDeviceModalLocString('batch-mode-positive-toast', {
        variable: remediationIdList.length,
      }),
    });
  };

  const renderError = () => {
    handleUseToast({
      id: 'error',
      type: 'negative',

      text: getDeviceModalLocString('batch-mode-negative-toast', {
        variable: selectedDeviceIdList.length,
      }),

      action: <a onClick={() => onConfigure()}> {getDeviceModalLocString('retry-toast')}</a>,
    });
  };

  const onUpdateCheckedData = (_payload) => {
    let newCheckboxFeature = [...checkboxFeature];
    newCheckboxFeature.map((data, index) => {
      if (data.id === _payload.target.name) {
        newCheckboxFeature[index].inputDisable = !_payload.target.checked;
        setcheckboxCount(newCheckboxFeature.filter((el) => el.inputDisable === false).length);
        setcheckboxFeature(newCheckboxFeature);

        const tempSelectedSubfeatures = newCheckboxFeature.filter(
          (data) => data.id === _payload.target.name,
        )[0].subfeatures;

        const result = getUpdatedCheckedFeatures(
          tempSelectedSubfeatures,
          editViewData,
          _payload.target.checked,
        );
        setEditViewData(result);
      }
    });
  };

  useEffect(() => {
    if (editViewData && editViewData.length) {
      let editViewDataCopy = [...editViewData];

      editViewDataCopy = editViewDataCopy.filter(
        (data) => data.isFmsPayload === true || data.isFmsPayload === undefined,
      );

      const customErrorArr = editViewDataCopy
        .map((_customErrorArrData) => {
          return getCustomValidate({
            editViewDataCopy,
            id: _customErrorArrData.name,
            value: _customErrorArrData.value,
          });
        })
        .filter(function (_res) {
          return _res !== undefined;
        });

      const ids = customErrorArr.map((_customErrorArrData) => _customErrorArrData.id);
      const filteredCustomErrorArr = customErrorArr
        .filter(({ id }, index) => !ids.includes(id, index + 1))
        .filter((_filteredData) => _filteredData.error);

      const customError = filteredCustomErrorArr.length ? false : true;

      const ldapSetupCredential = editViewDataCopy.find(
        (data) => data.name === 'ldap-setup.credential',
      );

      if (
        ldapSetupCredential &&
        (ldapSetupCredential.value === 'true' ||
          ldapSetupCredential.value === 'ldap-setup.credential.device-user')
      ) {
        editViewDataCopy.find((item) => item.name === 'ldap-setup.bind-prefix').required = true;
        editViewDataCopy.find((item) => item.name === 'ldap-setup.domain-name').required = false;
        editViewDataCopy.find((item) => item.name === 'ldap-setup.password').required = false;
        editViewDataCopy.find((item) => item.name === 'ldap-setup.domain-name').value = '';
        editViewDataCopy.find((item) => item.name === 'ldap-setup.password').value = '';
      }

      if (
        ldapSetupCredential &&
        (ldapSetupCredential.value === 'false' ||
          ldapSetupCredential.value === 'ldap-setup.credential.admin')
      ) {
        editViewDataCopy.find((item) => item.name === 'ldap-setup.bind-prefix').required = false;
        editViewDataCopy.find((item) => item.name === 'ldap-setup.bind-prefix').value = '';
        editViewDataCopy.find((item) => item.name === 'ldap-setup.domain-name').required = true;
        editViewDataCopy.find((item) => item.name === 'ldap-setup.password').required = true;
      }

      const requiredSettings = editViewDataCopy.filter(
        (data) =>
          (data.required === true || data.required === undefined) &&
          data.rulesEffect === false &&
          data.isChecked,
      );

      let customValid = false;
      const minPasswordLength = editViewDataCopy.filter(
        (data) => data.name === 'ews-password.min-length',
      )[0]?.value;
      const ewsPasswordValue = editViewDataCopy.filter(
        (data) => data.name === 'ews-password.value',
      )[0]?.value;
      const ss = editViewDataCopy.filter((data) => data.name !== 'ews-password.value');
      const ssSettings = ss.filter(
        (data) =>
          (data.required === true || data.required === undefined) &&
          data.rulesEffect === false &&
          data.isChecked,
      );

      if (minPasswordLength == '0' && ewsPasswordValue === '') {
        customValid = true;
      } else {
        customValid = false;
      }

      const requiredSettingsCount = customValid ? ssSettings.length : requiredSettings.length;

      const valid = requiredSettingsCount
        ? requiredSettings.filter(
            (data) =>
              (data.required == undefined || data.required) &&
              data.rulesEffect === false &&
              !data.error &&
              !data.confirmpasswordError &&
              data.value !== '' &&
              data.value !== null &&
              data.isChecked,
          ).length === requiredSettingsCount
        : false;

      const rr = requiredSettings.filter(
        (data) =>
          (data.required == undefined || data.required) &&
          data.rulesEffect === false &&
          !data.error &&
          !data.confirmpasswordError &&
          data.value !== '' &&
          data.value !== null &&
          data.isChecked,
      );
      const hasLessThanTwoApps = selectedData.some((item) => item.software.apps.length > 1);
      if (requiredSettings[0]?.name === `${FleetSvcPolicyAttributeId.App_Deployment}.apps`) {
        const appConfigs = Array.isArray(requiredSettings[0]?.value)
          ? requiredSettings[0].value
              .filter(app => app.config.length > 0)
              .map(app => app.config)
          : [];
        setEnable(!valid || !customError || !appConfigs.length || !hasLessThanTwoApps);
      } else {
        setEnable(!valid || !customError);
      }
    }
  }, [editViewData, checkboxCount]);
  return (
    <Style.CustomModal
      maxWidth="calc(100% + 40px + 40px)"
      expanded
      closeOnBlur={false}
      show={showModal}
      footer={
        <Footer
          onClose={onClose}
          onSave={onConfigure}
          tempSettings={tempSettings}
          isFetching={isFetching}
          selectedData={selectedData}
          featuresLength={featuresData.length}
          checkboxCount={checkboxCount}
          editViewData={editViewData}
          disabled={enable}
          isWex={isWex}
        />
      }
    >
      <EditDataContext.Provider
        value={{
          checkboxFeature,
          selectedCategory,
          selectedFeatures,
          setSelectedCategory,
          setSelectedFeatures,
          offset,
          setOffset,
          onUpdateTempData,
          tempRulesEffect,
          editViewData,
          onUpdateCheckedData,
          searchValue,
          setSearchValue,
          checkedFeaturesToggle,
          setCheckedFeaturesToggle,
          selectedCategoryName,
          setSelectedCategoryName,
          selectedNode,
          setSelectedNode,
          customErrorData,
          setCustomErrorData,
          tempSettings,
          setEditViewData,
          setTempRulesData,
          setTempRulesEffect,
        }}
      >
        <Header />

        <Style.SubTitle>{getDeviceModalLocString('batch-mode_modal-subtitle')}</Style.SubTitle>
        {showModal ? <Body selectedData={selectedData} isWex={isWex} /> : null}
      </EditDataContext.Provider>
    </Style.CustomModal>
  );
};

export default BatchView;
