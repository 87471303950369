import { AnchorMenu } from '@veneer/core';
import React, { useEffect, useState, useContext } from 'react';
import { CategoryEnum } from '../../../config/CategoryEnums';
import LocalizeHelper from '../../../helper/LocalizeHelper';
import { defaultAnchorPosition } from '../../../utils/dynamicMfeConstants';
import { Wrapper } from './Styles';
import { shellProps } from '../../../shellProps';

export const AnchorContainer = (props) => {
  const { configurableFeatures } = props;
  const [anchorMenuItems, setAnchorMenuItems] = useState([]);
  const { getDeviceCategoryLocString } = LocalizeHelper();
  const {
    containerId
  } = useContext(shellProps);

  useEffect(() => {
    const items = createAnchorMenuItems();
    setAnchorMenuItems(items);
  }, [configurableFeatures]);

  useEffect(() => {
    const items = createAnchorMenuItems();
    setAnchorMenuItems(items);
  }, [configurableFeatures]);

  const createAnchorMenuItems = () => {
    const _anchorMenuItems = Object.keys(CategoryEnum)
      .map((oneKey, i) => {
        const categoryKey = CategoryEnum[oneKey];
        const categoryData = configurableFeatures.filter(
          (data) => data.categoryName === categoryKey,
        );

        if (categoryData.length) {
          return {
            anchor: categoryKey,
            label: getDeviceCategoryLocString(categoryKey),
          };
        }
      })
      .filter((element) => {
        return element !== undefined;
      }) .filter((element) => {
        return element !== undefined;
      })
      .sort((a, b) => a.label.localeCompare(b.label));

    return _anchorMenuItems;
  };
  return (
    <Wrapper>
      <AnchorMenu
        containerId={containerId}
        items={anchorMenuItems}
        position={defaultAnchorPosition}
        data-testid={'id-anchor-menu'}
      />
    </Wrapper>
  );
};
