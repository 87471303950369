import React from 'react';
import * as Style from './Styles';

const InfoModal = ({ title, content, show, handleClose, id }) => (
  <Style.InfoModalWrap
    maxWidth="calc(100% + 40px + 40px)"
    expanded
    closeButton
    show={show}
    onClose={handleClose}
    closeOnBlur={true}
    children={
      <Style.InfoModalContainer>
        <Style.InfoModalHeader data-testid={`info-modal-Header-${id}`}>{title}</Style.InfoModalHeader>
        <Style.InfoModalContent data-testid={`info-modal-Content-${id}`}>{content}</Style.InfoModalContent>
      </Style.InfoModalContainer>
    }
  />
);

export default InfoModal;
